/*******************************
    Colors
********************************/

.text-light-black {
    color: $light-black;
}

.text-red {
    color: $red;
}

.text-green {
    color: $green !important;
}

.text-white {
    color: $white;
}

.text-gray {
    color: $darkGray;
}

.text-black {
    color: $black;
}

.text-dark-warning {
    color: $darkWarning;
}

.text-light-gray {
    color: $lightGray;
}

.br-bottom {
    border: none;
    border-bottom: 1px solid #ccc;
}

/*******************************
    fill vector icon colors
********************************/

.fill-green {
    fill: $green;
}

.fill-white {
    fill: $white;
}
.fill-red {
    fill: $red;
}

.fill-warning {
    fill: $warning;
}

.fill-dark-warning {
    fill: $darkWarning;
}

.fill-gray {
    fill: $gray;
}
/*******************************
    border Colors
********************************/
.br-green {
    border-color: $green !important;
}

.br-red {
    border-color: $red !important;
}

.br-light {
    border-color: $lightGray !important;
}
/*******************************
    Background Colors
********************************/
.bg-light {
    background-color: $lightGray !important;
}
.bg-green {
    background-color: $green !important;
    color: $white !important;
    // -moz-appearance: none;
    // -webkit-appearance: none;
    // appearance: none;
}

.bg-red {
    background-color: $red !important;
    color: $white !important;
}

.bg-orange {
    background-color: $darkWarning;
    color: white;
}

.bg-warning {
    background-color: $warning;
    color: white;
}

.bg-blue {
    background-color: $blue;
}

.bg-light-gray {
    background-color: $lightGray;
    // color: $light-black;
}

.bg-trans {
    background-color: transparent;
    color: $light-black;
}

.bg-gray {
    background-color: #eee;
}

.bg0 {
    // color: $light-black;
    // background-color: #ededed;
    color: #555555;
    background-color: #c5ecb285;
}

.bg1 {
    background-color: $white;
}

.right-center-bg-img {
    position: absolute;
    right: 0px;
    top: 50%;
    width: 120px;
    height: auto;
}

.left-center-bg-img {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 120px;
    height: auto;
}

.right-top-bg-img {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 120px;
    height: auto;
}

.left-top-bg-img {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 120px;
    height: auto;
}

.left-bottom-bg-img {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 200px;
    height: auto;
}

.right-bottom-bg-img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 200px;
    height: auto;
}
/*******************************
    Divider
********************************/

.divider-full {
    border: 1px dashed #e9e9e9;
    display: inline-block;
    height: 1px;
    margin: 10px auto;
    width: 100%;
    vertical-align: middle;
}

.divider {
    background-color: $green;
    display: inline-block;
    height: 2px;
    width: 20px;
}

/*******************************
    Fonts size
********************************/
.light-font {
    font-weight: 300;
}

/*******************************
    Hights
********************************/
.height-80 {
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
}
.full-height {
    min-height: 90vh;
}

.height-1 {
    min-height: 80vh;
}

.min-height-10 {
    min-height: 10vh !important;
}
.min-height-20 {
    min-height: 20vh !important;
}
.min-height-30 {
    min-height: 30vh !important;
}
.min-height-40 {
    min-height: 40vh !important;
}
.min-height-50 {
    min-height: 50vh !important;
}
.min-height-60 {
    min-height: 60vh !important;
}
.min-height-70 {
    min-height: 70vh !important;
}
.min-height-80 {
    min-height: 80vh !important;
}
.min-height-85 {
    min-height: 85vh !important;
}
.min-height-90 {
    min-height: 90vh !important;
}
.min-height-100 {
    min-height: 100vh !important;
}

/*******************************
    Widths
********************************/
.full-width {
    width: 100%;
}
.w-search {
    width: 50%;
}
.table-picture img {
    width: 100px;
    height: auto;
}

.min-width-10 {
    min-width: 10vh !important;
}
.min-width-15 {
    min-width: 15vh !important;
}
.min-width-20 {
    min-width: 20vh !important;
}
.min-width-30 {
    min-width: 30vh !important;
}
.min-width-40 {
    min-width: 40vh !important;
}
.min-width-50 {
    min-width: 50vh !important;
}
.min-width-60 {
    min-width: 60vh !important;
}
.min-width-70 {
    min-width: 70vh !important;
}
.min-width-80 {
    min-width: 80vh !important;
}
.min-width-85 {
    min-width: 85vh !important;
}
.min-width-90 {
    min-width: 90vh !important;
}
.min-width-100 {
    min-width: 100vh !important;
}

/*******************************
    Border Radius
********************************/
.bor-1 {
    border-radius: 1rem !important;
}
.bor-t-1 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}

.bor-2 {
    border-radius: 1.25rem !important;
}
.bor-t-2 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
}

.bor-3 {
    border-radius: 3rem !important !important;
}

.bor-tl {
    border-top-left-radius: 1.25rem !important;
}

.bor-tr {
    border-top-right-radius: 1.25rem !important;
}

.bor-bl {
    border-bottom-left-radius: 1.25rem !important;
}

.bor-br {
    border-bottom-right-radius: 1.25rem !important;
}

.form-control-danger,
.form-control-danger:focus {
    border: 1px solid $red;
}

.fs-0-7 {
    font-size: 0.7rem !important;
}

.fs-0-9 {
    font-size: 0.9rem !important;
}

.fs-1 {
    font-size: 1rem !important;
}

.fs-1 {
    font-size: 1rem !important;
}

.fs-1-2 {
    font-size: 1.2rem !important;
}

.fs-2 {
    font-size: 2rem !important;
}

.fs-3 {
    font-size: 3rem !important;
}

.fs-4 {
    font-size: 4rem !important;
}
.fs-10 {
    font-size: 10px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.sale {
    position: absolute;
    z-index: 10;
    padding: 5px;
    border-radius: 0rem 0rem 1rem 0rem;
    left: 0;
}

.custom-shadow {
    box-shadow: 0rem 0.125rem 0.5rem 0.05rem rgba(85, 85, 85, 0.075) !important;
}

// .modal-content {
//     border: none;
//     z-index: 1;
// }

.modal {
    display: block;
}

.cursor-pointer {
    cursor: pointer;
}

.vh-60 {
    height: 60vh;
}

.cp-1-25 {
    padding: 1.25rem;
}
