.table {
    overflow-x: auto;
    margin-bottom: 0;
}

.table tbody tr {
    border-left: none;
    border-right: none;
    background-color: $white;
}

.table td,
.table th {
    border-top: none;
    border-bottom: 1px solid #f5f4f0;
}

.table td {
    vertical-align: middle;
    color: $light-black;
}

.table thead th {
    border: none;
}

.cart-edit {
    position: relative;
    width: 140px;
}

.table {
    display: table;
    white-space: nowrap;
}

.table-cart-total {
    border-bottom: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    display: inline-block;
    // margin-bottom: 50px;
    padding: 20px 0;
    vertical-align: middle;
    width: 100%;
    background-color: $white;
}

.table-cart-total .float-left {
    width: 40%;
}

.table-cart-total .float-right {
    width: 50%;
}

.table-cart-image {
    height: auto;
}

.table-cart-image img {
    width: 100px;
    height: auto;
}

.cart-count {
    position: absolute;
    border-radius: 2em;
    color: #fff;
    font-size: 10px;
    font-weight: 100;
    left: 14px;
    line-height: 14px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 12px;
    background: $green;
}

.cart-count-1 {
    position: absolute;
    border-radius: 2em;
    color: #fff;
    font-size: 10px;
    font-weight: 100;
    left: -20px;
    line-height: 14px;
    text-align: center;
    top: -1px;
    width: 12px;
    background: $green;
}

.remove-cart {
    position: absolute;
    top: 10px;
    right: 10px;
}

.cart-items {
    height: 90vh;
    overflow: auto;
}

.cart-content {
    display: inline-flex;
}

.cart-content-total,
.cart-content-qty,
.cart-content-name {
    height: auto;
    // width: 90%;
}

.cart-content-qty div {
    border-radius: 25px;
}
// .table thead {
//     display: flex;
// }

// .table tr,
// .table th {
//     display: flex;
//     width: 100%;
// }
.mt65{
    margin-top:65px
}
.mt80{
    margin-top:80px
}
.mt95{
    margin-top:95px
}
