// .social-list {
//     padding-top: 10px;
// }
.social-list a {
    padding-right: 15px;
}
.footer-bg {
    background-image: url("/img/extra/bg-img-2.webp");
}
.footer-secondary {
    position: relative;
}

.footer-secondary p {
    margin: auto;
}

.footer-secondary > li a {
    text-transform: none;
}

.footer-secondary .footer-pattern {
    position: relative;
    z-index: 1;
    background-color: #ededed;
}

.footer-secondary .to-top {
    position: absolute;
    display: block;
    bottom: 60%;
    right: 0;
    left: 0;
    color: $red;
    cursor: pointer;
    font-size: 22px;
    // height: 30px;
    line-height: 30px;
    margin: auto;
    text-align: center;
    // width: 30px;
    z-index: 2;
}

.footer-secondary .to-top:hover,
.footer-secondary .to-top:focus {
    color: $green;
    cursor: pointer;
}

.modal-body,
.modal-content {
    border-radius: 1rem !important;
}

.store-icon img {
    width: 30%;
}

.h_iframe iframe {
    width: 100%;
    height: 100vh;
}
.h_iframe {
    height: 100vh;
}

.list svg {
    margin-right: 0.5rem;
}

.list a:hover path {
    fill: $green;
}
.w-15{
    width: 15%;
}
.inline{
    display: inline;
}

.w-35{
    width: 40%;
}
.h-35{
    height:35px;
}

 /* for desktop */
 .whatsapp_float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 10px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon-bootom {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    // .whatsapp-icon {
    //     margin-top: 10px;
    // }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

.store-icon-img{
    width: 113px;
    height: auto;
}
