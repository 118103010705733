/* ---------------------------------------------------
    Header Media Queries
----------------------------------------------------- */

/* ---------------------------------------------------
    Max width and lower ones and not more then Max
----------------------------------------------------- */

// Small devices (landscape phones, 576px and lower ones)
@media (max-width: 320px) {
    .xs-none {
        display: none;
    }
}

@media (max-width: 400px) {
    .banner-normal-db {
        min-height: 110vh !important;
    }
    .banner-normal {
        min-height: 65vh !important;
    }
    .banner-sticky {
        min-height: 65vh !important;
    }
}

@media (max-width: 576px) {
    .main-logo {
        width: 300px;
        height: 20px;
    }
}

@media (max-width: 576px) {
    // .owl-theme .owl-nav .owl-prev {
    //     top: 20%;
    // }

    // .owl-theme .owl-nav .owl-next {
    //     top: 20%;
    // }

    .banner-normal {
        min-height: 33vh;
    }

    .banner-sticky {
        min-height: 20vh;
    }

    .cat-title {
        padding-left: 5%;
    }

    .cat-title h1 {
        font-size: 1.5rem;
    }

    .owl-dots {
        top: 50%;
    }

    .owl-dots .owl-dot span {
        padding: 6px;
    }

    .owl-dots .active span {
        padding: 6px;
    }

    .search-wrapper {
        position: absolute;
        top: 45px;
        left: 23%;
        width: 73%;
        right: 0;
        z-index: 99;
    }

    .brand-title {
        font-size: 22px;
    }
    .main-logo {
        width: 300px;
        height: 20px;
    }

    .navbar {
        padding: 0.5rem 0rem;
    }

    .header-cart-icon {
        height: 2rem;
        width: 2rem;
    }

    .modal-body .widget-svg {
        width: 1.2rem !important;
        height: 1.2rem !important;
    }

    .main-banner {
        height: 170px;
        padding: 10px;
    }
    .main-banner .content {
        padding: 30px 10px;
        border-radius: 1rem;
    }
    .main-banner .content p {
        font-size: 12px;
        margin: 0;
    }
    .main-banner .content h1 {
        font-size: 16px;
    }
    .pattern img,
    .footer-secondary .footer-pattern img {
        height: 25px;
        width: 100%;
    }

    .table {
        display: block;
        overflow-x: auto;
    }

    .btn-mini-cart {
        border: none;
    }

    // .product-media {
    //     background-color: #f7f6f2;
    //     position: relative;
    //     width: 100%;
    //     height: 170px;
    // }
    // .section-title {
    //     font-size: 20px;
    //     letter-spacing: 1px;
    // }
    // .section-title .round-shape:before,
    // .section-title .round-shape:after {
    //     top: 16px;
    // }
    .tab-items {
        min-width: 145px;
        margin: 10px;
    }
    .review-caption .title {
        display: grid;
    }

    .table-picture img {
        width: 50px;
    }

    .table-cart-image img {
        width: 70px;
        height: auto;
    }

    .product-title .fs-1 {
        font-size: 0.75rem;
    }
    .product-title .h5 {
        font-size: 1rem;
    }
    .no-suggestions,
    .suggestions {
        width: calc(50% + 10rem) !important;
    }

    h2,
    .h2 {
        font-size: 1.5rem;
    }
    .section-title .round-shape:before,
    .section-title .round-shape:after {
        top: 20px;
    }
    .section-title {
        font-size: 1.5rem;
    }

    .cart-items {
        height: 60vh;
        overflow: auto;
    }
    .review-wrap {
        border-bottom: 1px solid #eeeeee;
        margin: 0;
        padding-bottom: 0;
        width: 100%;
    }
}

// Medium devices (tablets, 768px and lower ones)
@media (max-width: 768px) {
    .shop-title,
    .faq-title {
        font-size: 2rem !important;
    }
    .pattern {
        margin-top: -10px;
    }
    .main-banner .content {
        padding: 30px 10px;
        margin: 0 5%;
    }
    .table-cart-total .float-left {
        width: 70%;
        padding-left: 20px;
    }

    .table-cart-total .float-right {
        width: 100%;
    }
    .table-cart-charges .float-right {
        float: none !important;
    }

    .store-icon img {
        width: 30%;
    }
    .btn-white-sm {
        padding: 10px 20px;
        font-size: 1.3rem;
    }
    .cart-content {
        display: block;
    }
    .cart-edit {
        position: absolute;
        width: 140px;
        bottom: 10px;
        right: 10px;
    }
    .cart-card {
        padding: 0.5rem;
    }
}

// Large devices (desktops, 992px and lower ones)
@media (max-width: 992px) {
    .pattern {
        margin-top: -15px;
    }
    .w-search {
        width: auto;
    }
    .main-banner .content {
        padding: 30px 10px;
        margin: 0 5%;
    }
}

// Extra large devices (large desktops, 1200px and lower ones)
@media (max-width: 1200px) {
    .pattern {
        margin-top: -20px;
    }
}

/* ---------------------------------------------------
    Min width and up and not lower then Min
----------------------------------------------------- */

// Small devices (landscape phones, 576px and up)
@media (min-width: 320px) {
    // .xs-none {
    //     display: block;
    // }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .review-caption .rating {
        position: absolute;
        margin-top: -30px;
        left: 71%;
        /*float: right;
        margin: 10px auto;*/
    }
    .no-suggestions,
    .suggestions {
        width: calc(27% + 9rem) !important;
    }
    .feedback-stars {
        margin: auto 5rem;
    }
}

@media (max-width: 575px) {
    .navbar-expand-lg {
        // display: contents !important;
        flex-basis: auto;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: contents !important;
        flex-basis: auto;
    }
    .no-suggestions,
    .suggestions {
        width: calc(30% + 9rem) !important;
    }

    .cart-content {
        width: 70%;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .no-suggestions,
    .suggestions {
        width: calc(39% + 9rem) !important;
    }

    .main-logo {
        width: 105px;
    }

    .owl-carousel .product-box {
        margin: 10px auto;
    }
    .owl-banner {
        margin-top: 80px;
    }
}

@media (max-width: 570px) {
    .owl-banner {
        margin-top: 10px;
    }
}
@media (min-width: 575px) {
    .owl-banner {
        margin-top: 110px;
    }
}
@media (min-width: 775px) {
    .owl-banner {
        margin-top: 90px;
    }
}

@media (max-width: 990px) {
    .main-logo {
        width: 105px;
        // height: 40px;
        height: auto;
        padding-top:5px
    }
}
@media (max-width: 765px) {
    .add-to-cart {
        text-align: center;
    }
}
@media (max-width: 758px) {
    .whatsapp-icon-bootom {
        margin-top: 9px;
    }
}
@media (max-width: 768px) {
    .mobile-navbar {
        display: block !important;
    }
}
@media (min-width: 770px) {
    .mobile-navbar {
        display: none !important;
    }
}
// @media (max-width: 764px) {
//    .breadcrumb{
//         margin-top:100px;
//     }
// }

@media (max-width: 450px) {
    .voucher {
        margin-left: 30px;
    }
}

@media(max-width: 490px){
    .discount-footer{
        font-size:10px;
    }
    .discount-footer-text{
        font-size: 15px;
    }
    .cookie-disclaimer {
        min-height:76px;
    }
    .whatsapp_float {
        bottom:7px;
        right:5px;
    }
    .ml-0{
        margin-left:0px !important;
    }
    .store-icon{
        width: 50px !important;
        height: 50px !important;
    }
}

@media(min-width: 100px) and (max-width: 502px){
    .fs-1, .product-price{
        font-size: 14px !important;
    }

}
@media(min-width: 600px) and (max-width: 900px){
    .fs-1, .product-price{
        font-size: 14px !important;
    }
}

@media(max-width: 1400px){
    .left-bottom-bg-img{
        display: none !important;
    }
    .right-top-bg-img{
        display: none !important;
    }
}

@media(max-width: 766px){
    .m-icons{
        margin-top:11px
    }
}

@media (min-width: 989px){
    .main-logo {
        width: 105px;
    }
}

@media (max-width: 600px){
    .cat-icon {
        width: 60px !important;
    }
    .category-icons {
        width: 80px;
    }
    .category-icons a {
        font-size: 12px;
        font-weight: 800;
    }
}
@media (max-width: 414px){
   .one-width{
        padding-left:0px !important;
        padding-right:0px !important;
   }
   .cat-title{
    font-size: 25px;
    font-weight: 600;
   }
   .item{
    width:150px
   }
}

@media (max-width: 574px){
    .checkout{
        padding-top:1px !important;
    }
 }

 @media (min-width: 574px) and (max-width: 766px) {
    .checkout{
        padding-top:90px !important;
    }
 }
 @media (min-width: 765px) {
    .checkout{
        padding-top:50px !important;
    }
 }

 @media (max-width: 580px) {
    .steper-image{
        width: 50px !important
    }
    .steper-margin{
        margin-left: 15px;
        padding-right: 53px;
    }
 }

 @media (min-width: 580px) {
    .cart-top{
        margin-top:65px
    }
 }
 @media (min-width: 400px) {
    .header-icon{
       display: block;
       display: visible;
    }
 }

 @media (max-width: 400px) {
    .customer_label{
        font-size: 13px !important;
    }
 }
 @media (min-width: 401px) and (max-width: 557px) {
    .customer_label{
        font-size: 13px !important;
    }
 }


 @media (max-width: 574px) {
    #main-section{
        margin-top: 110px !important;
    }
 }

