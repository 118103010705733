.rlt-prd-wrapper {
    border-bottom: 1px solid #efefef;
    border-radius: 10px;
    padding: 0 20px 15px;
    margin-bottom: 30px;
}

.rlt-prd-img {
    display: block;
    vertical-align: middle;
}

.rlt-prd-img img {
    max-height: 80px;
    max-width: 80px;
}

.rlt-content {
    display: block;
    padding-left: 25px;
    vertical-align: middle;
}

.rlt-btn {
    display: block;
    padding-left: 25px;
    vertical-align: middle;
}
