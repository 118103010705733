.product-img {
    width: 300px;
    height: 300px;
    border-radius: 15px;
}
.br-8{
    border-radius: 8px;
}

.rounded-3 {
    border-radius: 1.25rem;
}

.nav-tabs .nav-link.active {
    color: $green;
}

.card-header {
    border-radius: 1.25rem 1.2rem 0 0 !important;
}

.signin-card-header {
    border-radius: 1rem 1rem 0 0 !important;
    padding:10px;
}
.review-time {
    font-size: 0.7rem;
    padding-top: 7px;
}

.review-caption {
    display: table-cell;
    text-align: left;
}

.review-caption .title {
    display: inline-block;
}

.review-wrap {
    border-bottom: 1px solid #eeeeee;
    margin: 40px auto auto;
    padding-bottom: 40px;
    width: 80%;
}

.review-img {
    float: left;
    margin-right: 25px;
    width: 64px;
}

// my account page stylesheet

.chk-body {
    padding-left: 25px;
}

.chk-body form .form-group {
    margin-bottom: 15px;
}

.chk-panel .panel {
    border-bottom: 1px solid #e5e5e5;
    border-radius: 25px;
    box-shadow: none;
    padding: 20px 30px;
}

.chk-panel .panel + .panel {
    margin-top: 0;
}

.chk-panel .panel:last-child {
    border-bottom: none;
}

.chk-form .radio-inline {
    display: inline-block;
    margin: 10px 0 0;
    width: 100%;
}

.chk-body p,
.chk-body ul li {
    color: #999;
}

.chk-body ul li {
    display: inline-block;
    margin: 8px 0 0;
    width: 100%;
}

.chk-body ul li span {
    line-height: normal;
    margin-right: 10px;
    vertical-align: text-bottom;
}
.br-15{
    border-radius:15px
}
.caption {
    text-align: center;
    padding: -3px 0 4px 0;
    height: 98px;
}
.bundle-item{
    width: 60px !important;
    height: 60px !important;
    border-radius: 15px;
}
.pro-product-caption{
    padding: 0 0 10px 0;
    height: 100px;
    position: absolute;
    top: 101px;
    left: 0px;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    width: 100%;
}
