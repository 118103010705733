$font-path: "fonts";
@font-face {
    font-family: farmtohome;
    // font-display: fallback;
    src: url(/fonts/Cocon-Light.otf);
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: farmtohome-urdu;
    // font-display: fallback;
    src: url(/fonts/csalt.ttf);
    font-weight: normal;
    font-display: swap;
}

/*---------------- 1.1 Preloader Starts ------------------*/

.loader {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #ffffff30;
    z-index: 9999;
    display: block;
}

.loader::after {
    content: "";
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: $green;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*******************************
    1 - GENERAL CSS STARTS
********************************/

html {
    overflow-x: hidden;
}
body {
    font-family: "farmtohome", Helvetica-normal, Arial, sans-serif;
    font-weight: normal;
    background-color: #ffffff;
    color: #525252;
    -webkit-font-kerning: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: visible !important;
    overflow-x: hidden;
    max-width: 100%;
}

a {
    color: $light-black;
}

a:hover,
a:active,
a:focus {
    color: $green;
    text-decoration: none;
    outline: 0;
}

label {
    margin-left: 5px;
}

img {
    width: 100%;
    height: auto;
}

main {
    height: 100vh;
    background-color: $light-black;
}

ul,
ol {
    list-style-type: none;
    list-style: none;
    padding-left: 0;
}

main {
    min-height: 100vh;
    background-color: $white;
}

section {
    position: relative;
}

button {
    outline: none !important;
}

input {
    outline: none;
}

textarea {
    width: 100%;
}

select option {
    background-color: $white;
    color: $black;
    border: none;
}

select option:hover,
select option:active {
    background-color: $red;
    color: $white;
    box-shadow: 0 0 10px 100px #000 inset;
}

.form-control:focus {
    color: #495057;
    background-color: $white;
    border-color: $green;
    outline: 0;
    box-shadow: none;
}

.dropdown-toggle:after {
    content: none;
}

.dropdown-menu.show {
    z-index: 2;
}

.badge {
    font-weight: 400;
}

/* ========================
        Overall Scroll
   ======================== */
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
    background: $red;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
    background: $red;
}

/* ========================
        Scroll for body
   ======================== */
/* width */
.table::-webkit-scrollbar,
.dropdown-cart ul::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}

/* Track */
.table::-webkit-scrollbar-track,
.dropdown-cart ul::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
.table::-webkit-scrollbar-thumb,
.dropdown-cart ul::-webkit-scrollbar-thumb {
    background: $green;
    border-radius: 10px;
}

/* Handle on hover */
.table::-webkit-scrollbar-thumb:hover,
.dropdown-cart ul::-webkit-scrollbar-thumb:hover {
    background: $mintGreen;
}
/**** GENERAL CSS END ****/

/* ========================
        Scroll for body
   ======================== */
/* width */
.cart-items::-webkit-scrollbar,
.cart-items div::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

/* Track */
.cart-items::-webkit-scrollbar-track,
.cart-items div::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
.cart-items::-webkit-scrollbar-thumb,
.cart-items div::-webkit-scrollbar-thumb {
    background: $green;
    border-radius: 10px;
}

/* Handle on hover */
.cart-items::-webkit-scrollbar-thumb:hover,
.cart-items div::-webkit-scrollbar-thumb:hover {
    background: $mintGreen;
}
/**** GENERAL CSS END ****/
/*******************************
    Header CSS STARTS
********************************/
.brand-title {
    font-size: 30px;
}

.main-header {
    background: transparent;
    background-color: transparent;
    position: absolute;
    z-index: 999;
    width: 100%;
}

.owl-dots .owl-dot span {
    background: transparent !important;
    border: 2px solid $green;
    padding: 9px;
}

.owl-dots .active span {
    background: $green !important;
    border: 2px solid $green;
    padding: 9px;
}

.owl-dots {
    position: absolute;
    left: 50%;
    top: 80%;
}

.owl-dot {
    position: relative;
    left: -50%;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: $white;
}

.sticky + main {
    padding-top: 50px;
}

.btn-menu {
    border: 1px solid #efefef;
    border-radius: 10px;
}

.btn-menu:focus {
    box-shadow: 0 0 0 0.2rem rgba(127, 186, 0, 0.5);
}

#navSearch .form-control:focus {
    box-shadow: none;
}

.dropdown-menu {
    left: auto;
    right: 20%;
    padding: 0;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
    background-color: $red;
    color: $white;
}

.btn-link,
.btn-link:hover {
    color: $red;
}

/**** Cart CSS Starts ****/

.cart {
    position: relative;
    display: inline-flex;
}

.cart-count {
    position: absolute;
    border-radius: 2em;
    color: $white;
    background-color: $red;
    font-size: 11px;
    font-weight: 900;
    left: 7px;
    line-height: 13px;
    text-align: center;
    top: -2px;
    width: 13px;
}
.dropdown-cart {
    min-width: 250px;
}
.dropdown-cart ul {
    max-height: 210px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
}
.cart-list,
.cart-btn,
.cart-total {
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    padding: 15px;
    color: $light-black;
}

.cart-img {
    width: 75px;
}

.cart-title {
    padding: 0 10px;
    width: 65%;
}

.cart-list {
    border-bottom: 1px solid #f7f6f2;
}

.cart-total {
    display: inline-block;
}

.cart-btn,
.cart-total {
    border-top: 1px solid #f7f6f2;
    // border-bottom: 1px solid #f7f6f2;
}

// .cartmodalcontent .form-control {
//     width: 40%;
// }

// .cartmodalcontent-1 .form-control {
//     width: 60%;
// }

.cartmodaldot {
    position: absolute;
    font-size: 50px;
    top: -22px;
    left: 2px;
}

/**** Cart CSS Ends ****/

/**** search CSS Starts ****/
.search-input {
    position: absolute;
    width: 75%;
    caret-color: red;
    transition: width 0.4s linear;
    left: 18px;
    top: 10px;
    padding: 10px 5px;
    background-color: white;
    border-radius: 35px;
    border: 1px solid #ccc;
    outline: 0;
}

.searchbar .search-icon-active {
    background: white;
    color: #e74c3c;
}

.search-icon {
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
}
.search-suggestion {
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    padding: 10px;
}

/**** search CSS ends ****/

.change-city select {
    border-radius: 2rem;
    border: none;
    font-size: 0.8rem;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: -260px;
    height: 100vh;
    z-index: 999;
    background-color: $white;
    transition: all 0.3s;
    overflow-y: auto;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar.active {
    left: 0;
}

#dismiss {
    text-align: center;
    background: #f44336;
    color: #ffffff;
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    transition: all 0.3s;
    border: none;
}

#dismiss:hover {
    background: #f54348;
    color: $white;
}

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: $light-black;
    z-index: 99;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.overlay.active {
    display: block;
    opacity: 0.8;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: $red;
    color: $white;
    // border-bottom: 1px solid $white;
}

// #sidebar ul li {
//     padding: 0.75rem 1rem;
// }

// #sidebar a {
//     font-size: 1rem;
// }

// #sidebar a:hover {
//     color: $red;
// }
// .sidebar-social {
// }

.sidebar-social-bottom {
    display: inline-flex;
    position: absolute;
    bottom: 2%;
    left: 6%;
}

.sidebar-social-bottom a {
    font-size: 1.5rem !important;
    padding: 0 15px;
}
/**** sidebar CSS END ****/

/**** Header CSS END ****/

/* ---------------------------------------------------
    Toast Start
----------------------------------------------------- */
#toast-container > div:hover {
    box-shadow: 0 0 12px #999 !important;
}

.toast-success {
    background-color: $green !important;
}

.toast-error {
    background-color: $red !important;
}

.toast-info {
    background-color: $blue !important;
}

.toast-warning {
    background-color: $warning !important;
}

/* ---------------------------------------------------
    Spinner start here
----------------------------------------------------- */
.spinner {
    position: absolute;
    // min-height: 50vh;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(239, 255, 230, 0.6);
    z-index: 999;
    display: block;
}

.spinner::after {
    content: "";
    display: block;
    position: absolute;
    left: 45%;
    top: 50%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: $green;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}

.loading {
    position: relative;
    width: 100%;
    background-color: rgba(239, 255, 230, 0.6);
    display: block;
}
.loading-charges {
    position: relative;
    width: 100%;
    // background-color: rgba(239, 255, 230, 0.6);
    display: block;
}

.loading::after {
    content: "";
    display: block;
    position: absolute;
    left: 45%;
    top: 45%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: $green;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}
.loading-charges::after {
    content: "";
    display: block;
    position: absolute;
    left: 10%;
    top: 30%;
    width: 25px;
    height: 25px;
    border-style: solid;
    border-color: #00c851;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}

/* ---------------------------------------------------
    end of spinner here
----------------------------------------------------- */

.input-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
}

.table-checkout {
    display: table !important;
}

// Search

.no-suggestions {
    color: #999;
    padding: 1rem;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    width: calc(300px + 1rem);
    position: fixed;
    z-index: 2;
    background-color: white;
}

.suggestions {
    // border: 1px solid #999;
    // border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(47% + 1rem);
    position: fixed;
    z-index: 2;
    background-color: white;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: $lightGray;
    color: $red;
    cursor: pointer;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #f3f3f3;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent !important;
}
textarea:focus::-moz-placeholder {
    color: transparent !important;
}
textarea:focus:-moz-placeholder {
    color: transparent !important;
}
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $red;
    opacity: 0.7; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $red;
    opacity: 0.7;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $red;
    opacity: 0.7;
}

.breadcrumb {
    font-size: 12px;
    background-color: $lightGray;
    margin-top:65px;
}

.arrow-icon {
    width: 1rem;
    height: 1rem;
}
.arrow-icon path {
    fill: $gray;
}

a:hover .arrow-icon path {
    fill: $green;
}

.app-banner {
    background-image: url("/img/extra/appbanner.webp");
    width: 100%;
    padding-top: 20%;
    height: 0;
    background-size: 100% 100%;
    position: relative;
    font-size: 20px;
}

.app-banner-content,
.app-banner-close {
    height: 100%;
    position: absolute;
    top: 0;
    background-color: transparent;
    border: 0;
}
.app-banner-close {
    width: 10%;
    overflow: hidden;
    left: 0;
}
.app-banner-content {
    width: 90%;
    overflow: hidden;
    right: 0;
}

.shop-title,
.faq-title {
    font-size: 3.5rem !important;
}

.faq-box .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0;
    border-radius: 0;
    background: #dff1d9;
    margin-bottom: 20px;
}

.faq-box .card-header {
    padding: 0;
    border-bottom: 0;
    background: none;
    border-radius: 0;
}

.faq-box .card-body {
    padding: 0 2rem;
}

.faq-box .btn-link::before {
    content: "-";
    line-height: 30px;
    position: absolute;
    // top: 30%;
    font-size: 20px;
    right: 25px;
    color: #252525;
    font-weight: 900;
}

.faq-box .btn-link.collapsed::before {
    content: "+";
    font-weight: 900;
    font-size: 20px;
}

.faq-box .btn-link {
    font-weight: 700;
    color: #6f6f6f;
    background-color: #dff1d9;
    display: block;
    font-size: 16px;
    padding: 20px 40px 20px 40px;
    border: 0;
    text-decoration: none;
    line-height: 28px;
    font-family: "Rubik", sans-serif;
    letter-spacing: -0.3px;
}

.bounce-animate {
    animation-name: float-bob;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes float-bob {
    0% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@keyframes float-bob {
    0% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #848484;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider-active:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 28px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.slider-inactive:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 7px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

input:checked + .slider {
    background-color: $green;
}

input:focus + .slider {
    box-shadow: 0 0 1px $green;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider-active,
.slider-inactive {
    border-radius: 34px;
}
.slider-active:before,
.slider-inactive:before {
    border-radius: 50%;
}

.switch-text-active {
    float: left;
    margin-top: 0.1rem;
    margin-left: 0.5rem;
    color: #ffffff;
}

.switch-text-inactive {
    float: right;
    margin-top: 0.1rem;
    margin-right: 1rem;
    color: #ffffff;
}

.card-header {
    background-color: transparent;
    border: none;
}

.pincode-parent {
    display: flex;
    flex-wrap: wrap;
}

.pincode-child {
    flex: 1 1 2%;
    margin: 6px;
    border: none;
    width: 40px;
    border-bottom: 1px solid $darkGray;
    color: $black;
    text-align: center;
}

.pincode-child:focus {
    border-bottom: 1px solid $green;
    color: $green;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: all 800ms ease;
}

.search-wrapper {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    // right: 0;
    z-index: 99;
}

.search-content {
    max-height: 40vh;
    overflow: auto;
}

#header{
    margin-bottom:10px;
    // margin-bottom:110px;
}
.header-icon{
    display: hidden;
 }
