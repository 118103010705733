// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "farmtohome", sans-serif !important;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$green: #00c851;

$red: #f44336;

$blue: #3498db;
$skyBlue: #33b5e5;

$white: #ffffff;

$lightGray: #f8f9fa;
$gray: #dee2e6;
$darkGray: #999;

$black: #555555;
$light-black: #00000080;

$warning: #ffc107;
$darkWarning: #ffa000;

$lightGreen: #dff1d9;
$mintGreen: #0be881;
