.banner-normal {
    min-height: 40vh;
}
.banner-sticky {
    min-height: 25vh;
}

.banner-normal-db {
    min-height: 90vh;
}
.banner-sticky-db {
    min-height: 100vh;
}

.featured-banner {
    width: 100%;
    position: absolute;
    bottom: 0%;
    z-index: 9;
}
.main-banner {
    position: relative;
    background-image: url("/img/banner/new-banner-03.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 20px;
    // height: 220px;
    height: 260px;
}
.main-banner img {
    width: 100%;
    height: 250px;
    max-height: 80%;
}

.main-banner .content {
    display: block;
    background-color: #dbfbb6a1;
    padding: 30px 15px;
    border-radius: 1rem;
    margin: 0 5%;
}

.main-banner .content h1 {
    font-size: 31px;
    color: #222222;
    letter-spacing: 1px;
}
.main-banner .content p {
    font-size: 18px;
    color: #222222;
    letter-spacing: 1px;
}

.pattern {
    position: relative;
    margin-top: -30px;
}

.title-wrap {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    text-align: center;
}

.sub-title {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.section-title {
    font-size: 40px;
    letter-spacing: 1px;
}
.section-title .round-shape {
    position: relative;
    // border: 1.5px solid #efefef;
    border-radius: 5em;
    display: inline-block;
    margin-top: 15px;
    padding: 5px 30px 8px;
}

.section-title .round-shape:before {
    right: 100%;
}
.section-title .round-shape:after {
    left: 100%;
}
.section-title .round-shape:before,
.section-title .round-shape:after {
    border-bottom: 2px solid #efefef;
    content: "";
    height: 0px;
    position: absolute;
    top: 25px;
    width: 100%;
    box-shadow: 0 0.5rem 1rem rgba(85, 85, 85, 0.15) !important;
}

.tabs-sm {
    font-size: 16px;
    margin: 0;
}

.tabs-sm li {
    display: inline-block;
    vertical-align: middle;
    margin: 3px;
}

.tabs-sm-button {
    border: 1px solid $green;
    border-radius: 25px;
    color: $green;
    display: inline-block;
    font-size: 14px;
    height: 30px;
    line-height: 18px;
    min-width: initial;
    padding: 5px 15px;
    text-align: center;
    vertical-align: middle;
    background: $white;
}

.tabs-sm-button:hover {
    background-color: $green;
    border-color: $green;
    color: $white;
}

// .tabs-lg a {
//     border: 2px solid #d8d8d7;
//     border-radius: 25px;
//     color: #9f9e9c;
//     display: inline-block;
//     height: 45px;
//     line-height: 30px;
//     min-width: 180px;
//     padding: 5px 10px;
//     text-align: center;
//     vertical-align: middle;
// }

// .tabs-sm button:hover,
// .tabs-lg a:hover {
//     background-color: $green;
//     border-color: $green;
//     color: $white;
// }

// .tabs-sm .active button,
// .tabs-lg .active a {
//     background-color: $green;
//     border-color: $green;
//     color: $white;
// }

// .tabs-sm a:focus,
// .tabs-sm a:hover,
// .tabs-lg a:focus,
// .tabs-lg a:hover {
//     background-color: $green;
//     border-color: $green;
//     color: $white;
// }
/*******************************
    custom horizontal scrolling starts here
********************************/
.tab-content {
    background-color: #fff;
    min-width: 100%;
    min-height: 100px;
    display: flex;
    overflow-x: auto;
}

.tab-items {
    // background-color: #e74c3c;
    min-width: 200px;
    margin: 15px 20px;
}
.shop-tab-items {
    min-width: 160px;
}

.tab-content::-webkit-scrollbar {
    display: none;
}

/*******************************
    Owl Carousel starts here
********************************/
.owl-carousel .owl-stage {
    width: 100%;
}

.owl-carousel .item {
    padding: 0 15px;
}

.owl-theme .owl-controls {
    text-align: center;
}

.owl-theme:hover .owl-nav .owl-prev,
.owl-theme:hover .owl-nav .owl-next {
    display: block !important;
}

.owl-theme:hover .owl-nav .disabled,
.owl-theme:hover .owl-nav .disabled {
    color: #ccc !important;
}
.owl-theme:hover .owl-nav .disabled:hover,
.owl-theme:hover .owl-nav .disabled:hover {
    color: #ccc !important;
    background: transparent;
}

.owl-theme .owl-nav .owl-prev {
    // display: none !important;
    position: absolute;
    top: 40%;
    left: 1%;
    border-radius: 25px;
    padding: 3px 6px;
    color: red !important;
}
.owl-theme .owl-nav .owl-next {
    // display: none !important;
    position: absolute;
    top: 40%;
    right: 1%;
    border-radius: 25px;
    padding: 3px 6px;
    color: red !important;
}
// .owl-theme .owl-nav .owl-prev:hover,
// .owl-theme .owl-nav .owl-next:hover {
//     color: $green !important;
//     background: transparent !important;
// }

.carousel-arrow {
    width: 2rem;
    height: 2rem;
}

.carousel-arrow path {
    // fill: $green;
    fill: white;
}

.nav-1 .owl-nav {
    border: 1px solid #efefef;
    border-radius: 1em;
    display: inline-block;
    margin-top: -52px;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
}

.nav-1 .owl-nav > div {
    color: #9f9f9f;
    display: inline-block;
    font-size: 10px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    width: 35px;
}

.nav-1 .owl-nav > div:hover,
.nav-1 .owl-nav > div:focus {
    background-color: #eb3b2d;
    color: #fff;
}

.nav-1 .owl-prev {
    border-right: 1px solid #efefef;
}

.product-box {
    border-radius: 10px;
    overflow: hidden;
    // margin: 8px 8px;
    text-align: center;
    position: relative;
    background-color: #fff;
    border: 1px solid $lightGray;
    margin-bottom: 5px;
}
.shop-product-box {
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-color: #fff;
    border: 1px solid $lightGray;
    margin:5px
}

.product-box:hover {
    box-shadow: 0 0 5px 0 rgba(229, 68, 68, 0.25);
}

.product-box:hover:after {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
}

.product-media {
    background-color: #f7f6f2;
    position: relative;
    width: 100%;
    height: 120px;
}

.product-media img {
    width: 100%;
    // height: 150px;
    // margin: auto;
    object-fit: cover;
}
.shop-product-media img {
    width: 150px !important;
    height: 150px;
}

.product-title-NameEn {
    height: 20px;
    text-align: left;
}

.product-title {
    // min-height: 50px;
    // font-size: 24px;
    // padding: 0.25rem 0;
}

.cat-title {
    padding-left: 1.5rem;
}

.cat-title h1 {
    font-size: 2rem;
}

.product-caption {
        padding: 0 0 10px 0;
        height: 43px;
        position: absolute;
        top: 77px;
        left: 0px;
        background: linear-gradient(to bottom, transparent 0%, black 100%);
        width: 100%;
}
.shop-product-caption {
    padding: 0 0 10px 0;
    position: absolute;
    top: 90px;
    left: 0px;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    width: 100%;
}

.product-box a {
    color: $light-black;
}
.products-banners ~ .owl-item {
    width: 100px;
}

.owl-carousel .owl-stage {
    display: flex;
}

.owl-carousel .owl-item img {
    width: auto;
    height: 100%;
}
.cookie-disclaimer {
    background: #000000;
    color: #fff;
    opacity: 0.8;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    min-height: 85px;
    position: fixed;
    animation: animateDiv 2s 4;
    animation-direction: alternate;
    animation-iteration-count: 1;
}
.cookie-disclaimer .container {
    text-align: center;
    // padding-top: 8px;
    // padding-bottom: 7px;
}

.cookie-disclaimer .cookie-close {
    float: right;
    padding: 10px;
    cursor: pointer;
}

.discount-footer {
    margin-bottom: 5px;
}

.discount-footer-text {
    color: #ed6a3c;
    font-size: 24px;
}

@keyframes animateDiv {
    0% {
        bottom: -50px;
        // top: 50px;
    }
    100% {
        bottom: 0px;
        // top: 0px;
    }
}

.arrow1 {
    animation: slide1 1s ease-in-out infinite;
    margin-left: 9px;
    font-size: 40px !important;
    background: #f44336;
    border-radius: 6px;
}

.w-90 {
    width: 90%;
}

// .slick-prev {
//     left: 3% !important;
//     z-index: 1;
//   }

//   .slick-next {
//     right: 4% !important;
//     z-index: 1;
//   }

//   .slick-prev:before, .slick-next:before

//   {
//     color: $green !important;
//     font-size: 35px !important;
//     opacity: 2;
//   }

  .ta-left{
    text-align: left;
  }

  .slick-slider {
    touch-action: auto;
    -ms-touch-action: auto;
    }

    .slick-track{
        margin-left: 0px !important;
    }

    .owl-carousel .item{
        padding: 0 5px !important;
    }

    .products-banners .owl-stage{
        // margin-left: 5%;
    }

    .products-banners .owl-stage {
        left: -32px;
     }

     .cat-icon{
        height: auto !important;
        width: 150px !important;
     }

h1 {
	position: relative;
	margin-top: 20px;
}
h1.one {
    // background: linear-gradient(#ffffff 0%, #ffffff 49%, $green 50%, $green 51%, #ffffff 52%, #ffffff 100%);
}
h1.one span {
	background: #fff;
	// padding: 0 20px;
	position: relative;
}

.one{
    text-align: center !important;
	background: transparent;
	// padding: 10px 40px;
    text-align: left;
	margin-bottom: 30px;
	line-height: 24px;
    color:$green;
}

.category-icons{
    width: 165px;
}
.item{
    width:190px
}
.product-row{
    display: flex;
    flex-wrap: wrap;
}
.see-more{
    color:$red;
    text-decoration:underline;
    cursor: pointer;
}

.home-divider{
    background-color: #00c851;
    height: 2px;
    width: 80px;
    text-align: center;
    margin-left: 47%;
    margin-bottom: 19px;
    margin-top: -15px;
}
