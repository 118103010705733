
/*======================
    404 page
=======================*/

.page-404 { 
    padding:40px 0;
    background:#fff;
}

.page-404  img { 
    width:100%;
}

.bg-404 {
    background-image: url(/img/extra/404.gif);
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
 }

.content-box-404 { 
    margin-top:-50px;
}