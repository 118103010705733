.progressbar {
    // margin-bottom: 30px;
    overflow: hidden;
}
.progressbar-content {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
}
.progressbar li {
    list-style-type: none;
    color: #99a2a8;
    font-size: 9px;
    width: calc(100% / 5);
    float: left;
    position: relative;
    // font: 500 13px/1 "Roboto", sans-serif;
    text-align: center;
    font-size: 14px;
}
.progressbar li:nth-child(1):after {
    content: "";
    // background-image: url("../img/extra/user.svg");
}
.progressbar li:nth-child(2):before {
    content: "";
    // background-image: url("../img/extra/user.svg");
}
.progressbar li:nth-child(3):before {
    content: "";
}
.progressbar li:nth-child(4):before {
    content: "";
}
.progressbar li:before {
    content: "";
    font: normal normal normal 30px/50px Ionicons;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    background: #eaf0f4;
    border-radius: 50%;
    margin: 0 auto 23px auto;
}

.progressbar li:nth-child(2):after,
.progressbar li:nth-child(3):after,
.progressbar li:nth-child(4):after,
.progressbar li:nth-child(5):after {
    content: "";
    width: 100%;
    height: 4px;
    background: #eaf0f4;
    position: absolute;
    left: -50%;
    top: 14px;
    z-index: -1;
}
// .progressbar li:last-child:after {
//     width: 150%;
// }
.progressbar li.active {
    color: $green;
}
.progressbar li.active:before,
.progressbar li.active:after {
    background: $green;
    color: white;
}

// feedback step

.feedback-stars {
    margin-bottom: 30px;
    overflow: hidden;
}
.feedback-icon {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
}
.feedback-stars li {
    list-style-type: none;
    color: #99a2a8;
    font-size: 9px;
    width: calc(100% / 5);
    float: left;
    position: relative;
    // font: 500 13px/1 "Roboto", sans-serif;
    text-align: center;
    font-size: 14px;
}
.feedback-stars li:nth-child(1):after {
    content: "";
    // background-image: url("../img/extra/user.svg");
}
.feedback-stars li:nth-child(2):before {
    content: "";
    // background-image: url("../img/extra/user.svg");
}
.feedback-stars li:nth-child(3):before {
    content: "";
}
.feedback-stars li:nth-child(4):before {
    content: "";
}
.feedback-stars li:before {
    content: "";
    font: normal normal normal 30px/50px Ionicons;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: block;
    background: #ffffff;
    border-radius: 50%;
    margin: 0 auto 15px auto;
}

.feedback-stars li:nth-child(2):after,
.feedback-stars li:nth-child(3):after,
.feedback-stars li:nth-child(4):after,
.feedback-stars li:nth-child(5):after {
    content: "";
    width: 100%;
    height: 2px;
    background: #aec0c7;
    position: absolute;
    left: -50%;
    top: 16px;
    z-index: -1;
}
.feedback-icon img {
    width: 35px;
}

.feedback-content li {
    height: 50px;
    width: 100%;
    cursor: pointer;
    background-color: #fff;
}
.feedback-content img {
    width: 30px;
    margin: auto 1rem;
}

.feedback-content h4 {
    margin: auto;
    // color: #ccc;
}

.feedback-forward-icon {
    text-align: right;
    margin: 0.5rem;
}

.feedback-content-2 {
    background-color: #fff;
}

.feedback-content-title {
    width: 100%;
    height: 50px;
    background-color: #dee2e6;
    cursor: pointer;
}

.feedback-back-icon {
    margin: 0.5rem;
}

.feedback-content-2 input[type="radio"],
.feedback-content-2 input[type="checkbox"] {
    height: 1.2rem;
    width: 1.2rem;
}

.feedback-content-2 .form-check-input {
    margin-left: -2rem;
}

.feedback-content-2 .form-check-label {
    font-size: 18px;
}

.feedback-content-2 input::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 3;
}

.payment-icon {
    border-radius: 1rem;
    border: none;
    background-color: white;
}

.payment-active {
    // border: 2px solid $green !important;
    box-shadow: 0px 1px 9px 1px #ccc;
}

.payment-title {
    width: 100%;
    height: 50px;
    background-color: $green;
    border-radius: 1rem 1rem 0 0;
    color: white;
}

.payment-card {
    margin: 1.5rem 0.5rem 1rem 0.5rem;
    min-height: 40vh;
    border-radius: 1rem;
}
