.voucher-card {
    width: 260px;
    height: 80px;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ededed;
    padding: 10px 10px;
    position: relative;
}

.main,
.copy-button {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
}
.voucher-card::after {
    position: absolute;
    content: "";
    height: 23px;
    right: -28px;
    border-radius: 40px;
    z-index: 1;
    top: 34px;
    background-color: #fff;
    width: 40px;
}

.voucher-card::before {
    position: absolute;
    content: "";
    height: 23px;
    left: -28px;
    border-radius: 40px;
    z-index: 1;
    top: 34px;
    background-color: #fff;
    width: 40px;
}

.co-img img {
    width: 25px;
    height: 25px;
    margin-left: 20px;
}
.vertical {
    border-left: 5px dotted black;
    height: 29px;
    position: absolute;
    left: 60%;
}

.voucher-content h1 {
    font-size: 12px;
    // margin-left: -95px;
    color: #565656;
}

.voucher-content h1 span {
    font-size: 12px;
}
.voucher-content h2 {
    font-size: 9px;
    // margin-left: -95px;
    color: #565656;
    text-transform: uppercase;
}

.voucher-content p {
    font-size: 8.5px;
    color: #696969;
    // font-weight: ;
    // margin-left: -95px;
}

.copy-button {
    margin: 12px 0 -5px 0;
    height: 45px;
    border-radius: 4px;
    padding: 0 5px;
    border: 1px solid #e1e1e1;
}

.copy-button input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 15px;
}

.copy-button button {
    padding: 5px 20px;
    background-color: #dc143c;
    color: #fff;
    border: 1px solid transparent;
}

.buy {
    position: absolute;
    content: "";
    bottom: 20px;
    left: 20px;
    background-color: #fff;
}

.modal-body-scrollable {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}





//payment method css
.frb-group {
	// margin: 15px 0;
}

.frb ~ .frb {
	margin-top: 15px;
}

.frb input[type="radio"]:empty,
.frb input[type="checkbox"]:empty {
	display: none;
}

.frb input[type="radio"] ~ label:before,
.frb input[type="checkbox"] ~ label:before {
	font-family: FontAwesome;
	content: '\f096';
	position: absolute;
	top: 50%;
	margin-top: -14px;
	left: 22px;
	font-size: 19px;
}

.frb input[type="radio"]:checked ~ label:before,
.frb input[type="checkbox"]:checked ~ label:before {
	content: '\f046';
}

.frb input[type="radio"] ~ label,
.frb input[type="checkbox"] ~ label {
	position: relative;
	cursor: pointer;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: white;
}

.frb input[type="radio"] ~ label:focus,
.frb input[type="radio"] ~ label:hover,
.frb input[type="checkbox"] ~ label:focus,
.frb input[type="checkbox"] ~ label:hover {
	box-shadow: 0px 0px 3px #333;
}

.frb input[type="radio"]:checked ~ label,
.frb input[type="checkbox"]:checked ~ label {
	color: #fafafa;
}

.frb input[type="radio"]:checked ~ label,
.frb input[type="checkbox"]:checked ~ label {
	background-color: #f2f2f2;
}

.frb.frb-default input[type="radio"]:checked ~ label,
.frb.frb-default input[type="checkbox"]:checked ~ label {
	color: #333;
}

.frb.frb-primary input[type="radio"]:checked ~ label,
.frb.frb-primary input[type="checkbox"]:checked ~ label {
	background-color: #337ab7;
}

.frb.frb-success input[type="radio"]:checked ~ label,
.frb.frb-success input[type="checkbox"]:checked ~ label {
	background-color: #5cb85c;
}

.frb.frb-info input[type="radio"]:checked ~ label,
.frb.frb-info input[type="checkbox"]:checked ~ label {
	background-color: #5bc0de;
}

.frb.frb-warning input[type="radio"]:checked ~ label,
.frb.frb-warning input[type="checkbox"]:checked ~ label {
	background-color: #f0ad4e;
}

.frb.frb-danger input[type="radio"]:checked ~ label,
.frb.frb-danger input[type="checkbox"]:checked ~ label {
	background-color: #d9534f;
}

.frb input[type="radio"]:empty ~ label span,
.frb input[type="checkbox"]:empty ~ label span {
	display: inline-block;
}

.frb input[type="radio"]:empty ~ label span.frb-title,
.frb input[type="checkbox"]:empty ~ label span.frb-title {
	font-size: 16px;
	font-weight: 700;
	margin: 5px 5px 5px 50px;
}

.frb input[type="radio"]:empty ~ label span.frb-description,
.frb input[type="checkbox"]:empty ~ label span.frb-description {
	font-weight: normal;
	font-style: italic;
	color: #999;
	margin: 5px 5px 5px 50px;
}

.frb input[type="radio"]:empty:checked ~ label span.frb-description,
.frb input[type="checkbox"]:empty:checked ~ label span.frb-description {
	color: #fafafa;
}

.frb.frb-default input[type="radio"]:empty:checked ~ label span.frb-description,
.frb.frb-default input[type="checkbox"]:empty:checked ~ label span.frb-description {
	color: #999;
}

.customer_type{
    display: flex;
}
.customer_type > div{
    // margin: 10px;
    padding: 20px;
}

.header-icon{
    display: none;
}



// toggle button css
.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 26px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    // background-color: #2196F3;
    background-color: #ec6a3c;
  }

  input:focus + .slider {
    // box-shadow: 0 0 1px #2196F3;
    box-shadow: 0 0 1px #ec6a3c;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
