.btn-success:hover,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:active,
.btn-success:focus {
    color: $white;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid $green;
    background-color: $green;
}

.btn-outline-success path {
    fill: $green;
}

.btn-outline-success {
    border: 1px solid $green;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:hover path {
    border: 1px solid $green;
    background-color: $green;
    color: $white;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
    fill: $white !important;
}

.btn-danger:hover,
.btn-danger:focus {
    border: 1px solid $red;
    background-color: $red;
    color: $white;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-outline-danger {
    border: 1px solid $red;
    background-color: $white;
    color: $red;
}

.btn-outline-danger path {
    fill: $red;
}

.btn-outline-danger:hover,
.btn-outline-danger:hover path,
.btn-outline-danger:focus {
    border: 1px solid $red;
    background-color: $red;
    color: $white;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
    fill: $white !important;
}

.btn-warning {
    color: $white;
}

.btn-warning:hover,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:active,
.btn-warning:focus {
    color: $white;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid $warning;
    background-color: $warning;
}

.btn-success.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-success.dropdown-toggle:hover {
    background-color: $green !important;
    border-color: $green !important;
}

.btn-default {
    border: none;
    outline: none;
    box-shadow: none;
    padding: none;
    margin: none;
    background: none;
}
.btn-default:hover,
.btn-default:focus .btn-default:active {
    border: none;
    box-shadow: none;
}

.btn-default:hover .icon-red path {
    fill: $red;
}

.btn-default:hover .icon-green path {
    fill: $green;
}

/* ==============================
        old class from here
=============================== */

.btn-yellow,
.btn-yellow-sm {
    border: 1px solid $yellow;
    background-color: $yellow;
    color: $white;
}

.btn-yellow:hover,
.btn-yellow:focus {
    color: $white;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
}

// disable button
.btn-success:disabled {
    border: 1px solid $green;
    background-color: $green;
    color: $white;
    cursor: not-allowed;
    opacity: 0.6;
}

.btn-cart-remove {
    border-radius: 1em;
    display: inline-block;
    font-size: 22px;
    font-weight: 900;
    // height: 25px;
    line-height: 24px;
    // min-width: 25px;
    // padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: #ccc;
}

.btn-cart-remove i:hover {
    border-radius: 2rem;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-close {
    border-radius: 1em;
    display: inline-block;
    font-size: 14px;
    font-weight: 900;
    // height: 25px;
    line-height: 24px;
    // min-width: 25px;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: #ccc;
}

.btn-close i:hover {
    border-radius: 2rem;
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 1px 3px 1px rgba(60, 64, 67, 0.16);
    transition: box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-cart-edit {
    border-radius: 2em;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 35px;
    padding: 0px 20px !important;
    padding: 0 5px;
    text-align: center;
    width: 135px;
    text-transform: uppercase;
    background-color: $white;
    border: 1px solid #e6e6e6;
    color: $light-black;
}

.btn-cart-plus,
.btn-cart-minus {
    border-radius: 1em;
    display: inline-block;
    font-size: 22px;
    font-weight: 900;
    // height: 25px;
    line-height: 24px;
    min-width: 25px;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: #ccc;
}

.btn-cart-minus {
    position: absolute;
    top: 5px;
    left: 5px;
}
.btn-cart-plus {
    position: absolute;
    top: 5px;
    right: 8px;
}

.btn-coupon {
    border: none;
    background: transparent;
    height: 23px;
    color: $white;
}

.btn-mini-cart {
    border-radius: 2em;
    font-size: 13px;
    font-weight: 500;
    // line-height: 35px;
    text-align: center;
    width: auto;
    padding: 0.25rem 0.75rem;
    background-color: $white;
    border: 1px solid #e6e6e6;
    color: $light-black;
}

.btn-border {
    border-radius: 2em;
    font-size: 13px;
    font-weight: 500;
    // line-height: 35px;
    text-align: center;
    // width: 145px;
    padding: 0 5px;
    background-color: transparent;
    border: 1px solid $green;
    color: $green;
}

.btn-coupon {
    background-color: $green;
    font-size: 13px;
    font-weight: 900;
    padding: 0px 20px;
    color: $white;
}

.btn-cart {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    line-height: 25px;
    // min-width: 120px;
    width: 100%;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    background-color: $red;
    color: $white;
    border: 1px solid $red;
    border-radius: 2rem;
}

// .btn-cart:hover {
//     box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
// }

.btn-facebook-login {
    background-color: #3c5a99;
    color: $white;
    display: inline-flex;
    align-items: center;
    padding: 7px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: $font-family-sans-serif;
    border-radius: 25px;
}

.btn-facebook-login:hover {
    background-color: #3555af;
}

.btn-google-login {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    border-radius: 2px;
    border: 1px solid #dddddd !important;
    font-size: 14px;
    font-weight: 500;
    font-family: $font-family-sans-serif;
    border-radius: 25px !important;
}

.btn-back {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    background: $green;
    border-radius: 1rem;
    padding: 5px;
    color: #fff;
    border: none;
}
.btn-spinner {
}

.btn-spinner:after {
    content: "";
    display: block;
    position: absolute;
    left: 45%;
    top: 6%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: #ffffff;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

.btn-default-a {
    border-radius: 1em;
    display: inline-block;
    line-height: 25px;
    background-color: transparent;
    border: none;
    color: $black;
    font-size: 14px;
}

.btn-default-a:hover {
    color: $green;
}

.button-orange{
    background-color: white;
    border: 2px solid #e66c42;
    color:#e66c42;
}
.button-orange:hover{
    color:white !important;
    background-color: #e66c42;
}
