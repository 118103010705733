/*
    ================
    Header icons
    ================
*/

.btn-icon {
    width: 1.2rem;
    height: 1.5rem;
}

.btn-menu-icon {
    width: 2rem;
    height: 2rem;
}

.btn-icon-outline-danger:hover path,
.btn-icon-outline-danger:hover polygon,
.btn-icon-outline-danger:hover rect,
.btn-icon-outline-success:hover path,
.btn-icon-outline-success:hover polygon,
.btn-icon-outline-success:hover rect,
.btn-icon-danger path,
.btn-icon-danger polygon,
.btn-icon-danger rect,
.btn-icon-success path,
.btn-icon-success polygon,
.btn-icon-success rect {
    fill: $white;
}

.btn-icon-danger circle,
.btn-icon-success circle {
    stroke: $white;
    stroke-width: 1;
}

.btn-icon-outline-danger path,
.btn-icon-outline-danger polygon,
.btn-icon-outline-danger rect {
    fill: $red;
}

.btn-icon-outline-danger circle {
    stroke: $red;
    stroke-width: 1;
}

.btn-icon-outline-success path,
.btn-icon-outline-success polygon,
.btn-icon-outline-success rect {
    fill: $green;
}

.btn-icon-outline-success circle {
    stroke: $green;
    stroke-width: 1;
}

// old icon classess here

.header-nav-icon {
    width: 1.5em;
    height: 1.5em;
}

.header-nav-icon path,
.header-nav-icon polygon,
.header-nav-icon rect {
    fill: $red;
}

.header-nav-icon circle {
    stroke: $red;
    stroke-width: 1;
}

.header-cart-icon {
    width: 1.7rem;
    height: 1.7rem;
}

.header-cart-icon path,
.header-cart-icon polygon,
.header-cart-icon rect {
    fill: $red;
}

.header-cart-icon circle {
    stroke: $red;
    stroke-width: 1;
}

.header-user-icon {
    width: 2em;
    height: 2em;
}

.header-user-icon path,
.header-user-icon polygon,
.header-user-icon rect {
    fill: $red;
}

.header-user-icon circle {
    stroke: $red;
    stroke-width: 1;
}

.header-user-icon-green {
    width: 2em;
    height: 2em;
}

.header-user-icon-green path,
.header-user-icon-green polygon,
.header-user-icon-green rect {
    fill: $green;
}

.header-user-icon-green circle {
    stroke: $green;
    stroke-width: 1;
}
.header-search-icon {
    width: 2em;
    height: 2em;
}

.header-search-icon path,
.header-search-icon polygon,
.header-search-icon rect {
    fill: $red;
}

.header-search-icon circle {
    stroke: $red;
    stroke-width: 1;
}
.header-search-icon {
    width: 1.5em;
    height: 1.5em;
}

.header-search-icon path,
.header-search-icon polygon,
.header-search-icon rect {
    fill: $red;
}

.header-search-icon circle {
    stroke: $red;
    stroke-width: 1;
}
/*
    ================
    sidebar icons
    ================
*/
.sidebar-svg-icon {
    width: 2em;
    height: 2em;
}

.sidebar-svg-icon path,
.sidebar-svg-icon polygon,
.sidebar-svg-icon rect {
    fill: $white;
}

.sidebar-svg-icon circle {
    stroke: $white;
    stroke-width: 1;
}
/*
    ================
    sign in icons
    ================
*/
.form-icon {
    width: 1.7em;
    height: 1.5em;
}

.form-icon path,
.form-icon polygon,
.form-icon rect {
    fill: $light-black;
}

.form-icon circle {
    stroke: $light-black;
    stroke-width: 1;
}

.footer-about-icon {
    width: 1.7em;
    height: 1.5em;
}

.footer-about-icon path,
.footer-about-icon polygon,
.footer-about-icon rect {
    fill: $light-black;
}

.footer-about-icon circle {
    stroke: $light-black;
    stroke-width: 1;
}

.footer-location-icon {
    width: 4em;
    height: 3.5em;
}

.footer-location-icon path,
.footer-location-icon polygon,
.footer-location-icon rect {
    fill: $light-black;
}

.footer-location-icon circle {
    stroke: $light-black;
    stroke-width: 1;
}

.facebook-svg-icon {
    width: 2em;
    height: 2em;
}

.facebook-svg-icon path,
.facebook-svg-icon polygon,
.facebook-svg-icon rect {
    fill: $white;
}

.facebook-svg-icon circle {
    stroke: $white;
    stroke-width: 1;
}

/*
    ================
    footer icons
    ================
*/
.footer-totop-icon {
    width: 2em;
    height: 2em;
}

.footer-totop-icon path,
.footer-totop-icon polygon,
.footer-totop-icon rect {
    fill: $red;
}

.footer-totop-icon circle {
    stroke: $red;
    stroke-width: 1;
}
.facebook-icon {
    width: 2em;
    height: 2em;
}

.facebook-icon path,
.facebook-icon polygon,
.facebook-icon rect {
    fill: $white;
}

.facebook-icon circle {
    fill: #3b5998;
    stroke-width: 1;
}

.whatsapp-icon {
    width: 1em;
    height: 1em;
}
.whatsapp-icon-footer {
    width: 2em;
    height: 2em;
}

.whatsapp-icon path:nth-child(1) {
    fill: #fff;
}

.whatsapp-icon path:nth-child(2) {
    fill: #55cd6c;
}

.whatsapp-icon path:nth-child(3) {
    fill: #fff;
}



.whatsapp-icon-footer path:nth-child(1) {
    fill: #fff;
}

.whatsapp-icon-footer path:nth-child(2) {
    fill: #55cd6c;
}

.whatsapp-icon-footer path:nth-child(3) {
    fill: #fff;
}



.insta-icon {
    width: 2em;
    height: 2em;
}

.insta-icon path {
    fill: url(#SVGID_1_);
}

.insta-icon circle {
    fill: url(#SVGID_2_);
}

.messenger-icon {
    width: 2em;
    height: 2em;
}

/*
    ================
    welcome icons
    ================
*/
.matrics-icon {
    width: 4em;
    height: 4em;
}

.matrics-icon path,
.matrics-icon polygon,
.matrics-icon rect {
    fill: $green;
}

.matrics-icon circle {
    stroke: $green;
    stroke-width: 1;
}

/*
    ================
    SupportNotify icons
    ================
*/
.support-notify-icon {
    width: 4rem;
    height: 4rem;
}

.support-notify-icon path,
.support-notify-icon polygon,
.support-notify-icon rect {
    fill: $red;
}

.support-notify-icon circle {
    stroke: $red;
    stroke-width: 1;
}

.modal-body .widget-svg {
    width: 2rem !important;
    height: 2rem !important;
}

.exclamation-icon {
    width: 1em;
    height: 1em;
}

.exclamation-icon path,
.exclamation-icon circle,
.exclamation-icon polygon,
.exclamation-icon rect {
    fill: $green;
}

.exclamation-icon circle {
    stroke: $green;
    stroke-width: 1;
}
