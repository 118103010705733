#circle {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-name: first;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
}
#tick {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-name: first;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
}

@keyframes first {
    to {
        stroke-dashoffset: 0;
    }
}
