.pos-prods-wrapper {
    min-height: 90vh;
    max-height: 90vh;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
}

.pos-bar {
    height: 5px;
    width: 100%;
}
.pos-cat-row {
    display: inline-flex;
    max-height: 180px;
    overflow-y: hidden;
    overflow-x: auto;
}

.pos-prod-wrapper {
    min-height: 18vh;
    max-height: 18vh;
}

.pos-cat-btns-float {
    position: fixed;
    top: 40%;
    left: -26px;
    z-index: 12;
    width: 30px;
    height: 200px;
}

.pos-cat-btn {
    position: relative;
    border-radius: 0.25em;
    display: inline-block;
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    min-width: 102px;
    height: 45px;
    margin: 0;
    // margin-bottom: 60px;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: $green;
    background-color: #fff;
    border: 1px solid $green;

    svg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 11;
        border-radius: 0.25rem;
    }

    rect {
        fill: none;
        stroke: none;
        stroke-width: 2;
        stroke-dasharray: 422, 0;
        stroke-dashoffset: 50;
        transition: all 0.35s linear;
    }
}

.pos-cat-btn:hover,
.pos-cat-btn:focus {
    color: #fff;
    background-color: $green;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
    rect {
        stroke: $green;
        stroke-width: 5;
        stroke-dasharray: 50, 100;
        stroke-dashoffset: 75;
        transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

.pos-btn-red {
    position: relative;
    border-radius: 0.25em;
    display: inline-block;
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    min-width: 102px;
    height: 45px;
    margin: 0;
    // margin-bottom: 60px;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: $white;
    background-color: $red;
    border: 1px solid $red;

    svg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 11;
        border-radius: 0.25rem;
    }

    rect {
        fill: none;
        stroke: none;
        stroke-width: 2;
        stroke-dasharray: 422, 0;
        stroke-dashoffset: 50;
        transition: all 0.35s linear;
    }
}

.pos-btn-red:hover,
.pos-btn-red:focus {
    rect {
        stroke: $white;
        stroke-width: 5;
        stroke-dasharray: 25, 410;
        stroke-dashoffset: 75;
        transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

.pos-btn-green {
    position: relative;
    border-radius: 0.25em;
    display: inline-block;
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    min-width: 102px;
    // height: 45px;
    margin: 0;
    // margin-bottom: 60px;
    padding: 6px 5px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    color: $white;
    background-color: $green;
    border: 1px solid $green;

    svg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 11;
        border-radius: 0.25rem;
    }

    rect {
        fill: none;
        stroke: none;
        stroke-width: 2;
        stroke-dasharray: 422, 0;
        stroke-dashoffset: 50;
        transition: all 0.35s linear;
    }
}

.pos-btn-green:hover,
.pos-btn-green:focus {
    color: $white;
    rect {
        stroke: $white;
        stroke-width: 5;
        stroke-dasharray: 30, 220;
        stroke-dashoffset: 75;
        transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

.pos-form-control:hover,
.pos-form-control:focus {
    border: 1px solid $green;
}

.pos-prod-parent {
    display: flex;
    flex-wrap: wrap;
}

.pos-prod-child {
    flex: 0 1 11%;
    margin: 1px;
}

.pos-prod-img {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
}

.sale-item {
    position: absolute;
    z-index: 1;
    left: 0;
    background: #00c851;
    color: white;
    width: 60px;
    top: 5px;
    left: 0px;
    font-size: 0.8rem;
    text-align: center;
}
.voucher-discount {
    position: absolute;
    z-index: 1;
    left: 0;
    background: #f44336;
    color: white;
    width: 120px;
    top: 5px;
    left: 5px;
    font-size: 0.8rem;
    text-align: center;
}

.prod-type {
    position: absolute;
    z-index: 1;
    color: white;
    font-size: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top:-2px;
    right:0px;
}
.prod-type button{
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
.shop-prod-type button{
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
.shop-prod-type {
    position: absolute;
    color: white;
    z-index: 1;
    font-size: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 50%;
    margin-left: 130px;
    margin-top: -7px
}
.product-sale {
    position: absolute;
    z-index: 1;
    left: 0;
    background: #00c851;
    color: white;
    width: 98px;
    transform: rotate(316deg);
    top: 10.5%;
    left: -2%;
    font-size: 0.8rem;
    text-align: center;
}

.pos-card {
    border-radius: 0.3rem;
    min-width: 105px;
    max-width: 105px;
    display: block;
}

.pos-btop-rounded {
    border-radius: 0.25rem 0.25rem 0 0;
}

.pos-cat-row::-webkit-scrollbar {
    width: 1px;
    height: 2px;
}
.pos-cart-item {
    border-bottom: 1px solid #ececec;
}

.pos-cart-img {
    width: 20%;
}
.pos-cart-img img {
    width: 60px;
    height: auto;
}

.num-wrapper {
    background-color: #f8f9fa;
}
.pos-btn-num {
    border-radius: 0.5em;
    display: inline-block;
    font-weight: 500;
    line-height: 40px;
    min-width: 70px;
    max-width: 70px;
    text-align: center;
    text-transform: uppercase;
    border: none;
}

.btn-num-white {
    padding: 5px 20px;
    font-size: 25px;
    background-color: white;
    color: #222222;
}

.btn-num-gray {
    padding: 5px 20px;
    font-size: 25px;
    background-color: #f8f9fa;
    color: blue;
}

.btn-num-text {
    padding: 5px 10px;
    font-size: 12px;
    background-color: #f8f9fa;
    color: blue;
    min-width: 80px;
    max-width: 80px;
}

.pos-info-logo {
    width: 60%;
}

.pos-nav-item {
    width: 50%;
}

.pos-nav-item .active {
    border: 1px solid $green;
    background-color: $green !important;
    color: #ffffff !important;
}

.pos-nav-link {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
}

.pos-order-item .pos-order-bar {
    width: 10px;
    background-color: $green;
}
.tab-content {
    // min-height: 83vh;
    max-height: 83vh;
}

// .pos-order-item .pos-order-detail {
// }

.pos-order-item .pos-order-br {
    width: 1px;
    background-color: $green;
}

.pos-product-btn {
    position: relative;
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: center;
    // display: flex;
    // height: 115px;
    svg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 11;
        border-radius: 0.25rem;
    }

    rect {
        fill: none;
        stroke: none;
        stroke-width: 2;
        stroke-dasharray: 422, 0;
        transition: all 0.35s linear;
    }
}

.pos-product-btn:hover,
.pos-product-btn:focus {
    rect {
        stroke: $green;
        stroke-width: 5;
        stroke-dasharray: 25, 310;
        stroke-dashoffset: 75;
        transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

// .wrapper {
//     display: flex;
//     width: 100%;
// }

.pos-sidebar {
    width: 290px;
    position: fixed;
    top: 60px;
    right: 10px;
    height: 90vh;
    z-index: 1;
    background: #fff;
    color: #6d6c6b;
    transition: all 0.3s;
    // border: 1px solid #6d6c6b;
}

.pos-sidebar.active {
    margin-right: -300px;
}

.pos-sidebar-toggle {
    position: absolute;
    top: 20px;
    left: -22px;
    // margin-left: -20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: -1;
}

.pos-sidebar-toggle-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    background: transparent;
    border-color: transparent;
}
.pos-sidebar-toggle-btn::after {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: $green;
    border-radius: 10px;
}

.pos-main-content {
    width: calc(100% - 300px);
    padding: 0 15px 0 0;
    min-height: 85vh;
    transition: all 0.3s;
    // position: absolute;
    top: 50px;
    left: 0;
}

.pos-main-content.active {
    width: 100%;
}
.pos-cart-wrapper {
    max-height: 57vh;
    min-height: 57vh;
    overflow-y: auto;
}
.pos-cart-total {
    position: absolute;
    border-radius: 2em;
    color: #fff;
    font-size: 10px;
    font-weight: 100;
    left: 18px;
    line-height: 14px;
    text-align: center;
    top: -3px;
    width: 14px;
    background: $red;
}

.btn-cart-edit {
    border-radius: 2em;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 35px;
    padding: 0px 20px !important;
    padding: 0 5px;
    text-align: center;
    width: 135px;
    text-transform: uppercase;
    background-color: $white;
    border: 1px solid #e6e6e6;
    color: $light-black;
}

.pos-btn-cart-edit {
    border-radius: 2em;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 35px;
    padding: 0px 20px !important;
    padding: 0 5px;
    text-align: center;
    width: 100px;
    text-transform: uppercase;
    background-color: $white;
    border: 1px solid #e6e6e6;
    color: $light-black;
}

.pos-btn-cart-plus,
.pos-btn-cart-minus {
    border-radius: 1em;
    display: inline-block;
    font-size: 22px;
    font-weight: 900;
    // height: 25px;
    line-height: 24px;
    min-width: 25px;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: #ccc;
}

.pos-btn-cart-minus {
    position: absolute;
    top: 5px;
    left: 5px;
}
.pos-btn-cart-plus {
    position: absolute;
    top: 5px;
    right: 42px;
}

.text-ellipsis {
    white-space: nowrap;
    // width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}
.cart-text-ellipsis {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.text-ellipsis-online-order {
    white-space: nowrap;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.text-ellipsis-shop-order {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.pos-cart-input {
    width: 40px;
    padding: 0px;
    height: 25px;
    text-align: center;
}

.pos-online-order-history-wrapper {
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
}

.pos-shop-order-history-wrapper {
    min-height: 83vh;
    max-height: 83vh;
    overflow-y: auto;
}

.a-icon {
    width: 40px;
}

.pos-search-icon {
    width: 1rem;
    height: 1rem;
}

.pos-search-icon path,
.pos-search-icon polygon,
.pos-search-icon rect {
    fill: #222222;
}

.pos-search-icon circle {
    stroke: #222222;
    stroke-width: 1;
}

.pos-btn-green-outline {
    border-radius: 0.25em;
    display: inline-block;
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    min-width: 102px;
    height: 45px;
    margin: 0;
    // margin-bottom: 60px;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border: 1px solid $green;
    background-color: #ffffff;
    color: $green;
}

.pos-btn-green-outline:hover {
    background-color: $green;
    color: #ffffff;
}

// .pos-btn-green {
//     box-shadow: none;
//     font-size: 15px;
//     padding: 5px;
//     color: #fff;
//     // min-width: 150px;
//     text-transform: uppercase;
//     border: 1px solid $green;
//     background-color: $green;
//     color: #ffffff;
// }

// .pos-btn-red {
//     box-shadow: none;
//     font-size: 15px;
//     padding: 5px;
//     color: #fff;
//     // min-width: 150px;
//     text-transform: uppercase;
//     border: 1px solid $red;
//     background-color: $red;
//     color: #ffffff;
// }

.pos-cart-inc {
    background: #888;
    padding: 1px 6px;
    border-radius: 0.5rem;
    color: #fff;
    min-width: 25px;
}

.pos-discount-input {
    height: 26px;
    // width: 75%;
    text-align: center;
    background-color: #f8f9fa;
    color: #222222;
}
.pos-order-search-wrapper {
    background-color: rgb(248, 249, 250);
    width: 80%;
}
.pos-order-search {
    height: 40px;
}
.pos-order-select-wrapper {
    width: 35%;
    border-left: 1px solid #ced4da;
}

.pos-order-select {
    background-color: rgb(248, 249, 250);
    border-radius: 0px;
    border: none;
    height: 40px;
}

.nav-pills .nav-link {
    border-radius: 0rem;
}

.react-date-picker {
    width: auto !important;
}

.react-date-picker__button {
    padding: 0px 10px !important;
}

.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker__button svg {
    stroke: #444444 !important;
}

.react-date-picker__clear-button {
    display: none !important;
}

.p-icon {
    width: 80px;
    height: 80px;
}

.print-order {
    width: 100mm;
    margin: 0 15px;
    font-family: Arial, sans-serif, monospace;
}

.pos-print-total {
    float: right;
    margin-right: 1.7rem;
}

.print-order h4 {
    font-size: 44px;
    font-weight: 600;
    margin: 0;
    color: #000;
    font-family: Arial, sans-serif, monospace;
}
.print-order h6 {
    color: #000;
    font-family: Arial, sans-serif, monospace;
}

.print-order p {
    margin: 0;
    font-size: 16px;
    color: #000;
    display: block;
}

@media print {
    @page {
        margin: 0 !important;
        size: 107mm 210mm;
        size: Portrait;
        font-family: Arial, sans-serif, monospace;
    }
}

.print-order .border-tb {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.border-t {
    border-top: 1px solid #000;
}

.st0 {
    fill: #f9d0b4;
}
.st1 {
    fill: #e9e9ea;
}
.st2 {
    fill: #6d6c6b;
}
.st3 {
    fill: #66cc66;
}
.st4 {
    fill: #f75c64;
}
.st5 {
    fill: #ffcd29;
}
.st6 {
    fill: #6dcc6d;
}
.st7 {
    fill: #231f20;
}

.pos-card-stats .card-icon {
    border-radius: 5px;
    padding: 10px;
    margin-top: -30px;
    float: left;
    text-align: center;
}

[type="date"] {
    background: #fff
        url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
        90% 50% no-repeat;
}

[type="date"]::-webkit-calendar-picker-indicator {
    // display: none;
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 10%;
}

.css-2613qy-menu {
    position: relative;
    overflow: hidden;
}

.paginate {
    display: flex;
}

.paginate button {
    color: $gray;
    // float: left;
    padding: 8px;
    // text-decoration: none;
    min-width: 40px;
    width: 40px;
    border-radius: 2rem;
    margin-right: 5px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    background-color: #fff;
}

.paginate button:first-child {
    border: 1px solid #eee;
}

.paginate button.active {
    background-color: $green;
    color: white;
    border: none;
}

.paginate button:hover:not(.active) {
    background-color: #ddd;
}

.pos-table-modal .table th,
.pos-table-modal .table td {
    padding: 0.5rem;
}
