.social-login span {
    width: 100%;
}

.btn-google-login div {
    background-color: transparent !important;
    margin-right: 0 !important;
}

.btn-google-login span {
    text-align: left;
    padding: 0px !important;
    font-weight: 900 !important;
}

///pos sign in

.pos-logo {
    width: 100px;
    height: auto;
}

.pos-form-icon {
    position: absolute;
    width: auto;
    bottom: 10px;
    right: 10px;
    margin-top: 0;
}

.pos-signin-user-icon {
    width: 1em;
    height: 1em;
}

.pos-signin-user-icon path,
.pos-signin-user-icon polygon,
.pos-signin-user-icon rect {
    fill: #222222;
}

.pos-signin-user-icon circle {
    stroke: #222222;
    stroke-width: 1;
}

.pos-signin-lock-icon {
    width: 1em;
    height: 1em;
}

.pos-signin-lock-icon path,
.pos-signin-lock-icon polygon,
.pos-signin-lock-icon rect {
    fill: #222222;
}

.pos-signin-lock-icon circle {
    stroke: #222222;
    stroke-width: 1;
}
